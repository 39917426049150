body {
  background-color: #f9f6ef;
  margin: 0;
  font-family: 'Playfair Display', serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #282828;
  overflow-x: hidden;
}

.container-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.container {
  width: 80%;
  max-width: 800px;
  text-align: center;
}

.title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.text {
  font-size: 1rem;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.text.visible {
  opacity: 1;
  transform: translateY(0);
}